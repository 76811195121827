import * as d3 from "d3";
import log from "loglevel";
import { createContext } from "react";
import {
    Configuration,
    EventControllerApi,
    EventDto,
    EventDtoImageAspectEnum,
    EventTimeDto,
    GetImprintLanguageEnum,
    ParticipantDto,
    ProcessAliveResponseDto,
    SiteControllerApi,
    UserControllerApi,
    UserDto
} from "../openapi/index";
import * as runtime from "../openapi/runtime";
import { backendBaseUrl, isDevEnv } from "./constants";
import { JwtUserDto, onErrorCallback } from "./types";

export const UserContext = createContext<JwtUserDto | undefined>(undefined);

const generateEvent = (): EventDto => {
    const eventTimes: EventTimeDto[] = ([{
        id: 1,
        start: new Date(Date.now() + (24 * 3600 * 1000)),
        end: new Date(Date.now() + (25 * 3600 * 1000))
    }]);

    return isDevEnv ? {
        description: "<strong>Lorem</strong> ipsum dolor sit amet, consectetur adipiscing elit. Duis imperdiet efficitur massa, quis sagittis mauris porttitor porta. Nunc libero turpis, consequat sit amet ante nec, aliquam eleifend neque. Integer ex ligula, pretium sed tincidunt sit amet, molestie quis est. Nunc luctus purus tincidunt feugiat lacinia. Proin ut convallis eros. Morbi accumsan purus pulvinar diam luctus varius. Fusce quis eleifend mauris, id tincidunt ipsum. Mauris eget dapibus urna. Suspendisse potenti. In ac lorem elit. Etiam sit amet consectetur ante, non sagittis mauris. Curabitur arcu odio, egestas non mollis quis, finibus vel dui. Donec accumsan, augue in ultrices semper, arcu leo ultricies libero, id tincidunt neque mauris ut elit. Mauris tempor, metus vitae condimentum vestibulum, sem est mattis magna, id efficitur magna orci eget massa. Pellentesque at massa in magna posuere ullamcorper non id ex. Donec tempor imperdiet malesuada. ",
        eventTypeHeadline: "Head Line",
        id: Math.floor(Math.random() * 10000),
        imageAspect: EventDtoImageAspectEnum.A32,
        imageSrcSet: "https://vids-dev.vwapps.run/assets/stagingbg_320.jpg 320w,https://vids-dev.vwapps.run/assets/stagingbg_640.jpg 640w,https://vids-dev.vwapps.run/assets/stagingbg_1280.jpg 1280w,https://vids-dev.vwapps.run/assets/stagingbg_2600.jpg 2600w",
        name: "Dummy event",
        ndaText: "<h1>Declaration of secrecy</h1><h2>29<sup>th</sup> International Marketing Conference – Web Conference</h2><p>Name: {name}</p><p>In the course of today’s presentation, you will receive detailed information concerning new products and product strategies. Since this product information represents company secrets of the Volkswagen Group, we ask you to agree to the following statement:</p><p>The secrecy obligation includes a complete ban on visual and sound recording (e.g. photo, film, video, electronic picture storing equipment or screen recording).</p><p>I hereby undertake to maintain strict secrecy and not to pass on any knowledge gained to a third party in respect of the product information that this event presents.</p>",
        uriId: "fake",
        chatEnabled: true,
        prerollUrl: "http://test.url",
        prerollAutoplayEnabled: false,
        prerollLoopEnabled: false,
        prerollSoundEnabled: false,
        streamUrl: "https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8",
        eventTimes: eventTimes
    } : {};
};

const generateParticipant = (): ParticipantDto => {
    return {
        acceptedToS: false,
        email: "test@mail.de",
        eventId: 3,
        firstname: "Max",
        lastname: "Mustermann",
        id: Math.floor(Math.random() * 10000),
        lastLogin: d3.timeHour.floor(new Date()),
        reminderSent: d3.timeHour.floor(new Date()),
    };
};

class BaseBackend {
    constructor(protected readonly onError: onErrorCallback, protected readonly accessToken: (() => Promise<string>) | undefined) {
    }

    protected async wrapBackendCall<D>(callData: Promise<runtime.ApiResponse<D>>, mockGenerator: (mockParams?: unknown[]) => D, errorDefault: D, mockParams?: unknown[]): Promise<D> {
        return callData.then((val) => {
            const raw = val.raw;
            return val.value().catch((reason): Promise<D> => {
                if (isDevEnv) {
                    log.info("returning mock data");
                    const result = mockGenerator(mockParams) as D;
                    log.info(result);
                    return Promise.resolve(result);
                } else {
                    log.info("recieved error: %o", raw);
                    if (raw.status === 404) {
                        return Promise.reject(raw);
                    }
                    this.onError(String(reason), "error");
                    return Promise.resolve(errorDefault);
                }
            });
        });
    }
}

const generateEventTimes = (): EventTimeDto[] => {
    return [
        { start: d3.timeHour.ceil(new Date()), end: d3.timeHour.ceil(new Date()) }
    ];
};

export class EventBackend extends BaseBackend {
    private readonly eventApi = new EventControllerApi(new Configuration({
        basePath: backendBaseUrl,
        accessToken: this.accessToken
    }));

    public async getAllEvents(): Promise<EventDto[]> {
        return this.wrapBackendCall(
            this.eventApi.getEventsRaw({ ignoreAdminFilter: true }),
            () => Array.from({ length: Math.floor(Math.random() * 5) + 1 }, generateEvent),
            []
        );
    }

    public async getAllAdminEvents(): Promise<EventDto[]> {
        return this.wrapBackendCall(
            this.eventApi.getEventsRaw({ ignoreAdminFilter: false }),
            () => Array.from({ length: Math.floor(Math.random() * 5) + 1 }, generateEvent),
            []
        );
    }

    public async getEvent(id: number): Promise<EventDto> {
        return this.wrapBackendCall(this.eventApi.getOneByIdRaw({ id: id }), generateEvent, {});
    }

    public async getEventByUri(eventUrlId: string): Promise<EventDto> {
        return this.wrapBackendCall(this.eventApi.getOneByUriRaw({ uriId: eventUrlId }), generateEvent, {});
    }

    public async getMySelf(uriId: string): Promise<ParticipantDto> {
        return this.wrapBackendCall(this.eventApi.getMySelfRaw({ uriId }), generateParticipant, {});
    }

    public async processAlive(): Promise<ProcessAliveResponseDto> {
        return this.wrapBackendCall(this.eventApi.processAliveRaw(), () => ({ instanceId: "mocked" }), {});
    }

    public async acceptTerms(uriId: string): Promise<void> {
        return this.eventApi.acceptTerms({ uriId }).catch(() => {
            log.info("error while accepting terms");
        });
    }

    public async unsubscribe(code: string): Promise<void> {
        return this.eventApi.unsubscribe({ codedLink: code }).catch(reason => this.onError(reason, "error"));
    }

    public async getFutureEventTimes(uriId: string): Promise<EventTimeDto[]> {
        return this.wrapBackendCall(this.eventApi.getFutureEventTimesRaw({ uriId }), generateEventTimes, []);
    }
}

const generateUser = (): UserDto => {
    return isDevEnv ? {
        admin: true,
        corporation: "Evil Corp.",
        email: "test@mail.com",
        givenName: "Max",
        familyName: "Mustermann"
    } : {};
};

export class UserBackend extends BaseBackend {
    private readonly userApi = new UserControllerApi(new Configuration({
        basePath: backendBaseUrl,
        accessToken: this.accessToken
    }));

    public async getMe(): Promise<UserDto> {
        return this.userApi.whoAmI().catch((reason) => {
            if (isDevEnv) {
                return generateUser();
            }
            throw reason;
        });
    }
}

const generateImprint = (): string => {
    return "dummyImprint";
};

export class SiteBackend extends BaseBackend {
    private readonly siteApi = new SiteControllerApi(new Configuration({ basePath: backendBaseUrl }));

    constructor(onError: onErrorCallback) {
        super(onError, undefined);
    }

    public async getImprint(lang: GetImprintLanguageEnum): Promise<string> {
        return this.wrapBackendCall(this.siteApi.getImprintRaw({ language: lang }), generateImprint, "");
    }
}
